(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name wbOrder.directive:wbOrderEmptyItemList
   * @restrict E
   * @element
   *
   * @description
   *
   * @example
   <example module="wbOrder">
    <file name="index.html">
      <wb-order-empty-item-list></wb-order-empty-item-list>
    </file>
   </example>
   *
   */
  angular
    .module('wbOrder')
    .directive('wbOrderEmptyItemList', wbOrderEmptyItemList);

  function wbOrderEmptyItemList() {
    return {
      restrict: 'E',
      scope: {
        orderType: '@'
      },
      templateUrl: 'wb-order/directives/wb-order-empty-item-list.tpl.html',
      controllerAs: 'wbOrderEmptyItemList',
      bindToController: true,
      controller: function () {
        var vm = this;
      }
    };
  }
}());
